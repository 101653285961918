import React, { useState, useEffect, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";

import { SystemSelectors } from "../../../../state";

import Modal from "../../../../components/Modal";
import MaterialCheckbox from "../../../../components/form/MaterialCheckbox";
import Loader from "../../../../components/Loader";

import {
  unspecifiedInteractionType,
  otherInteractionCategoryId,
  decodeSelectedInteractionTypes,
} from "./shared";
import { mapToObject, distinct } from "../../../../lib";

export default function ChooseColumnsModal({
  defaultInteractionTypes,
  onSelect,
  onCancel,
  pageRoute,
}) {
  const {
    query: { si: selectedInteractionTypes },
  } = pageRoute;

  const [includedInteractionTypeIds, setIncludedInteractionTypeIds] = useState(
    decodeSelectedInteractionTypes(
      selectedInteractionTypes || defaultInteractionTypes,
    ),
  );
  const [interactionTypesByCategory, setInteractionTypesByCategory] = useState(
    {},
  );
  const [loading, setLoading] = useState(true);

  const allInteractionTypes = useSelector(SystemSelectors.interactionTypes);
  const interactionCategories = useSelector(
    SystemSelectors.sortedInteractionCategories,
    shallowEqual,
  );

  useEffect(
    function partitionInteractionTypesByCategory() {
      setLoading(true);

      // start with an object keyed by category ID and pointing to empty array
      const startObj = mapToObject(
        interactionCategories,
        (c) => c.id,
        (_) => [],
      );

      // insert interaction types into the correct category's array
      const _interactionTypesByCategory = allInteractionTypes.reduce(
        (acc, item) => {
          acc[item.category.id].push(item);
          return acc;
        },
        startObj,
      );

      // add "Unspecified" in its own category
      _interactionTypesByCategory[otherInteractionCategoryId] = [
        unspecifiedInteractionType,
      ];

      setInteractionTypesByCategory(_interactionTypesByCategory);

      setLoading(false);
    },
    [interactionCategories, allInteractionTypes],
  );

  const getInteractionTypeIdsForCategory = useCallback(
    (categoryId = null) => {
      if (categoryId) {
        return interactionTypesByCategory[categoryId].map(
          (interactionType) => interactionType.id,
        );
      }

      const selectedInteractionTypeIds = allInteractionTypes.map(
        (interactionType) => interactionType.id,
      );
      selectedInteractionTypeIds.push(unspecifiedInteractionType.id);
      return selectedInteractionTypeIds;
    },
    [allInteractionTypes, interactionTypesByCategory],
  );

  const onSelectAllInteractionTypes = useCallback(
    (categoryId = null) => {
      const deselectedInteractionTypeIds =
        getInteractionTypeIdsForCategory(categoryId);
      const _includedInteractionTypeIds = [
        ...includedInteractionTypeIds,
        ...deselectedInteractionTypeIds,
      ];
      setIncludedInteractionTypeIds(distinct(_includedInteractionTypeIds));
    },
    [getInteractionTypeIdsForCategory, includedInteractionTypeIds],
  );

  const onClearAllInteractionTypes = useCallback(
    (categoryId = null) => {
      if (!categoryId) {
        setIncludedInteractionTypeIds([]);
      } else {
        const selectedInteractionTypeIds =
          getInteractionTypeIdsForCategory(categoryId);
        const _includedInteractionTypeIds = includedInteractionTypeIds.filter(
          (interactionTypeId) =>
            !selectedInteractionTypeIds.includes(interactionTypeId),
        );
        setIncludedInteractionTypeIds(_includedInteractionTypeIds);
      }
    },
    [getInteractionTypeIdsForCategory, includedInteractionTypeIds],
  );

  const onResetInteractionTypes = useCallback(() => {
    setIncludedInteractionTypeIds(
      decodeSelectedInteractionTypes(defaultInteractionTypes),
    );
  }, [defaultInteractionTypes]);

  const onCheckInteractionType = useCallback(
    (event, typeId) => {
      if (event.target.checked) {
        setIncludedInteractionTypeIds(
          distinct([...includedInteractionTypeIds, typeId]),
        );
      } else {
        const indexToRemove = includedInteractionTypeIds.indexOf(typeId);
        if (indexToRemove > -1) {
          const _includedInteractionTypeIds = [...includedInteractionTypeIds];
          _includedInteractionTypeIds.splice(indexToRemove, 1);
          setIncludedInteractionTypeIds(_includedInteractionTypeIds);
        }
      }
    },
    [includedInteractionTypeIds],
  );

  const renderInteractionTypesForCategory = (category) => (
    <div>
      <div className="mt-24 mb-16">
        <span className="archivo-extra-bold large-text mr-16">
          {category.name}
        </span>
        {renderSelectClearLinks(category.id)}
      </div>
      <div className="interaction-types-container">
        {interactionTypesByCategory[category.id].map((type) => (
          <MaterialCheckbox
            label={type.name}
            checked={includedInteractionTypeIds.includes(type.id)}
            onChange={(event) => onCheckInteractionType(event, type.id)}
          />
        ))}
      </div>
    </div>
  );

  const renderInteractionTypes = () => (
    <div>
      {interactionCategories.map((category) =>
        renderInteractionTypesForCategory(category),
      )}

      {renderInteractionTypesForCategory({
        id: otherInteractionCategoryId,
        name: "Other",
      })}
    </div>
  );

  const renderSelectClearLinks = (categoryId = null) => {
    const isAnySelected = !categoryId
      ? !!includedInteractionTypeIds.length
      : interactionTypesByCategory[categoryId].some((interactionType) =>
          includedInteractionTypeIds.includes(interactionType.id),
        );

    const isAnyNotSelected = !categoryId
      ? includedInteractionTypeIds.length !== allInteractionTypes.length + 1 // account for unspecified
      : interactionTypesByCategory[categoryId].some(
          (interactionType) =>
            !includedInteractionTypeIds.includes(interactionType.id),
        );

    return (
      <>
        {isAnyNotSelected && (
          <span
            className="link-text uppercase-text mr-20"
            onClick={() => onSelectAllInteractionTypes(categoryId)}
          >
            Select all
          </span>
        )}
        {isAnySelected && (
          <span
            className="link-text uppercase-text mr-20"
            onClick={() => onClearAllInteractionTypes(categoryId)}
          >
            Clear all
          </span>
        )}
        {!categoryId && (
          <span
            className="link-text uppercase-text"
            onClick={onResetInteractionTypes}
          >
            Reset to defaults
          </span>
        )}
      </>
    );
  };

  return (
    <Modal show={true}>
      <div className="modal-container" key="campConfirmationModal">
        <div className="modal card interactions-summary-choose-column-modal">
          <div className="flex flex-align-center flex-justify-space mt-24 mb-12">
            <div>
              <span className="archivo-extra-bold xxl-text mr-16">
                Choose columns
              </span>
              {renderSelectClearLinks()}
            </div>
            <i
              className="material-icons link-text-secondary"
              onClick={onCancel}
            >
              close
            </i>
          </div>

          <p className="accent-text mb-24">
            Please note: Students who do not have any interactions in the
            selected columns will still be displayed. To exclude them, filter by
            interaction type.
          </p>

          {loading ? <Loader /> : renderInteractionTypes()}

          <div className="modal-btns">
            <p className="link-text uppercase-text fw-700" onClick={onCancel}>
              Cancel
            </p>
            <p
              className="link-text uppercase-text ml-40 fw-700"
              onClick={() => onSelect(includedInteractionTypeIds)}
            >
              Select
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
