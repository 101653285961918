import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";
import queryString from "query-string";

const defaultStateObjectData = {
  loading: false,
  success: true,
  data: {},
  error: null,
  errorMessage: null,
};

const defaultStateArrayData = {
  loading: false,
  success: true,
  data: [],
  error: null,
  errorMessage: null,
};

export async function getAppliedStudentInteractionTypesAsync(filters) {
  const params = {
    gradYear: filters.gradYear,
    interactionTypeId: filters.interactionType,
    rangeType: filters.rangeType,
    searchText: filters.searchTerm,
  };

  const newState = { ...defaultStateObjectData };

  try {
    const response = await AuthRequest.get(
      `Engagement/appliedStudentInteractionTypes?${queryString.stringify(
        params,
      )}`,
    );
    newState.data = response.data.payload;
  } catch (err) {
    newState.error = err;
    newState.errorMessage = ApiCallErrorMessageHandler(err);
    newState.success = false;
  }
  return newState;
}

export async function getStudentInteractionsSummaryAsync(
  page,
  resultsView,
  filters,
  isExport = false,
) {
  const params = {
    gradYear: filters.gradYear,
    interactionTypeIdsStr: filters.includedInteractionTypes,
    interactionTypeId: filters.interactionType,
    isExport,
    numResults: resultsView,
    page,
    rangeType: filters.rangeType,
    searchText: filters.searchTerm,
    sortBy: filters.sortBy,
  };

  const newState = { ...defaultStateObjectData };

  try {
    const response = await AuthRequest.get(
      `Engagement/studentInteractionsSummary?${queryString.stringify(params)}`,
    );
    newState.data = response.data.payload;
  } catch (err) {
    newState.error = err;
    newState.errorMessage = ApiCallErrorMessageHandler(err);
    newState.success = false;
  }

  return newState;
}

export async function getKpiScoreRanges() {
  const newState = { ...defaultStateArrayData };

  try {
    const response = await AuthRequest.get("Engagement/kpiScoreRanges");
    newState.data = response.data.payload;
  } catch (err) {
    newState.error = err;
    newState.errorMessage = ApiCallErrorMessageHandler(err);
    newState.success = false;
  }

  return newState;
}

export async function getVibrancyMetrics(monthYears) {
  const newState = { ...defaultStateArrayData };

  try {
    const response = await AuthRequest.get(
      `Engagement/vibrancyMetrics?${queryString.stringify({ monthYears })}`,
    );
    newState.data = response.data.payload;
  } catch (err) {
    newState.error = err;
    newState.errorMessage = ApiCallErrorMessageHandler(err);
    newState.success = false;
  }

  return newState;
}

export async function getEngagementEmailPreferences() {
  const newState = { ...defaultStateObjectData };

  try {
    const { data } = await AuthRequest.get(`Engagement/emailPreferences`);
    newState.data = data.payload;
  } catch (err) {
    newState.error = err;
    newState.errorMessage = ApiCallErrorMessageHandler(err);
    newState.success = false;
  }

  return newState;
}

export async function submitEngagementEmailPreferences(payload) {
  const newState = { ...defaultStateObjectData };
  try {
    const { data } = await AuthRequest.post(
      `Engagement/emailPreferences`,
      payload,
    );
    newState.data = data.payload;
  } catch (err) {
    newState.error = err;
    newState.errorMessage = ApiCallErrorMessageHandler(err);
    newState.success = false;
  }

  return newState;
}
