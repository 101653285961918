import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  convertToArray,
  getYearOptions,
  Navigation,
  PageURL,
} from "../../../lib";
import debounce from "lodash.debounce";
import { Button, Grid, Tooltip } from "@material-ui/core";
import ConfirmationModal from "../../../components/ConfirmationModal";
import TableResultsView from "../../../components/TableResultsViewDeprecated";
import { AuthSelectors } from "../../../state";
import { JewishInterestLevels } from "../../../state/engagement/types";

export default function StudentsListFilters(props) {
  const {
    campuses,
    chabadHouseTags = [],
    deleteTag,
    loadData,
    pageRoute: { query, page, params },
    showSearch = true,
    viewStudentsByGroup,
  } = props;

  const {
    ca = "", // campusId
    er = "", // engagement roundup
    ge = "", // gender
    gd = "", //grad/undergrad
    gr = "", // groups / CH tags
    gy = "", // grad years
    jil = "", // Jewish interest level
    se = "", // search
    so = "", // sort
  } = query;
  const gyArray = convertToArray(gy);

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const { isEnrolledInEngagement } = useSelector(
    AuthSelectors.engagementAccess,
  );
  const showEngagementAdvancedFilters = isShliach && isEnrolledInEngagement;

  const [showDeleteGroupConfirmModal, setShowDeleteGroupConfirmModal] =
    useState(false);

  useEffect(
    () => {
      if (!showEngagementAdvancedFilters) {
        onChange("er", null);
        onChange("jil", null);
      }
    },
    // onChange is not memoized properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showEngagementAdvancedFilters],
  );

  const onChange = (name, val, debounce) => {
    const url = PageURL.to(page, params, {
      ...query,
      [name]: val
        ? typeof val === "object"
          ? val
          : encodeURIComponent(val)
        : undefined,
      p: undefined, //reset page to 1
    });
    Navigation.redirect(url);

    setTimeout(debounce ? onFilterDebounce : onFilter, 0);
  };

  const onFilter = useCallback(() => {
    loadData();
  }, [loadData]);

  const onFilterDebounce = debounce(onFilter, 500);

  const chabadHouseTagOptions = [
    { label: "All student groups", value: "" },
    ...chabadHouseTags?.map((s) => ({
      ...s,
      key: s.id,
      value: s.id,
      label: s.tagName,
    })),
  ];

  const selectedTagOption = chabadHouseTagOptions?.find(
    (t) => t.id === parseInt(decodeURIComponent(gr)),
  );
  const caOptions = [
    { label: "All Campuses", value: "" },
    ...(campuses
      ? campuses.map((c) => ({
          value: c.id,
          label: c.name,
        }))
      : []),
  ];

  const gdOptions = [
    { label: "<Select Demographic>", value: "" },
    { label: "Grad", value: "Grad" },
    { label: "Undergrad", value: "Undergrad" },
    { label: "Alumnus", value: "Alumnus" },
    { label: "Unknown", value: "Unknown" },
  ];

  const geOptions = [
    { label: "<Select Gender>", value: "" },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Not Set", value: "Unknown" },
  ];

  const gyOptions = getYearOptions(2005, new Date().getFullYear() + 15).map(
    (yr) => ({
      value: yr,
      label: yr,
    }),
  );

  const jilOptions = [
    { label: "<Select Status>", value: "" },

    ...Object.values(JewishInterestLevels).map((jil) => {
      return { label: jil, value: jil };
    }),
  ];
  const soOptions = [
    { label: "Last Name", value: "lastName" },
    { label: "First Name", value: "firstName" },
    { label: "Newest", value: "newest" },
    { label: "Oldest", value: "oldest" },
  ];

  const engagementRoundupOptions = [
    { label: "<Filter by monthly roundup>", value: "" },
    { label: "New students", value: "NewStudents" },
    { label: "Month absentees", value: "MonthAbsentees" },
    { label: "Frequent student absentees", value: "FrequentStudentAbsentees" },
    {
      label: "Interested student absentees",
      value: "InterestedStudentAbsentees",
    },
    {
      label: "Connected student absentees",
      value: "ConnectedStudentAbsentees",
    },
  ];

  const showCampusFilter = caOptions.length > 2;

  return (
    <>
      <ConfirmationModal
        message={`Are you sure you want to delete the group ${selectedTagOption?.label} ?`}
        show={showDeleteGroupConfirmModal}
        cancel={() => setShowDeleteGroupConfirmModal(false)}
        confirm={async () => {
          await deleteTag();
          onChange("gr", undefined);
          setShowDeleteGroupConfirmModal(false);
        }}
      />
      <div className="mobile-block full-width students-filters">
        <div className="flex">
          {showSearch && (
            <div className="search-input" style={{ marginBottom: "12px" }}>
              <input
                name="se"
                onChange={(event) =>
                  onChange(event.target.name, event.target.value, true)
                }
                placeholder="Search Student Name"
                type="text"
                value={decodeURIComponent(se)}
              />
              <i className="material-icons accent-text-secondary pointer">
                search
              </i>
            </div>
          )}

          {viewStudentsByGroup && (
            <div
              className={showSearch ? "ml-16" : "mb-8"}
              style={{ minWidth: 300 }}
            >
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: "rgba(0, 0, 0, 0.06)",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "rgba(0, 0, 0, .38)",
                    padding: "0 8px",
                    fontSize: "16px",
                  }),
                }}
                placeholder="All student groups..."
                options={chabadHouseTagOptions || []}
                name={"gr"}
                value={selectedTagOption}
                onChange={(val, action) => {
                  if (action?.action === "clear") {
                    setShowDeleteGroupConfirmModal(true);
                    return false;
                  }
                  onChange("gr", val?.value);
                }}
                isClearable={!!gr}
                components={{ ClearIndicator: ClearIndicator }}
              />
            </div>
          )}
        </div>
        <Grid container justifyContent="space-between" className="ml-8">
          <Grid container item md={10} xs={12}>
            <div className="student-list-filter" style={{ minWidth: 210 }}>
              <p className="mr-8 nowrap-text">Filter by</p>
              <Select
                className="student-list-filter-select"
                classNamePrefix="student-list-filter-select"
                closeMenuOnSelect={false}
                isMulti={true}
                onChange={(vals) =>
                  onChange(
                    "gy",
                    vals.map((v) => v.value),
                  )
                }
                options={gyOptions}
                placeholder="All Grad Years"
                value={gyOptions.filter((g) =>
                  gyArray.includes(g.value.toString()),
                )}
              />
            </div>
            {/* Campus filter is only available for shluchim with more than one campus association */}
            {showCampusFilter && (
              <Grid item md={2} xs={12} className="student-list-filter">
                <p className="mr-8 nowrap-text">Filter by</p>
                <Select
                  className="student-list-filter-select"
                  classNamePrefix="student-list-filter-select"
                  defaultValue={caOptions[0]}
                  isSearchable={false}
                  onChange={(val) => onChange("ca", val.value)}
                  options={caOptions}
                  value={
                    caOptions.find(
                      (c) => c.value.toString() === decodeURIComponent(ca),
                    ) || caOptions[0]
                  }
                />
              </Grid>
            )}
            <Grid item md={2} xs={12} className="student-list-filter">
              <p className="mr-8 nowrap-text">Filter by</p>
              <Select
                className="student-list-filter-select"
                classNamePrefix="student-list-filter-select"
                defaultValue={geOptions[0]}
                isSearchable={false}
                onChange={(val) => onChange("ge", val.value)}
                options={geOptions}
                value={
                  geOptions.find(
                    (g) => g.value.toString() === decodeURIComponent(ge),
                  ) || geOptions[0]
                }
              />
            </Grid>
            <Grid item md={2} xs={12} className="student-list-filter">
              <p className="mr-8 nowrap-text">Filter by</p>
              <Select
                className="student-list-filter-select"
                classNamePrefix="student-list-filter-select"
                defaultValue={gdOptions[0]}
                isSearchable={false}
                onChange={(val) => onChange("gd", val.value)}
                options={gdOptions}
                value={
                  gdOptions.find(
                    (g) => g.value.toString() === decodeURIComponent(gd),
                  ) || gdOptions[0]
                }
              />
            </Grid>
            <Grid item md={2} xs={12} className="student-list-filter">
              <p className="mr-8 nowrap-text">Sort by</p>
              <Select
                className="student-list-filter-select"
                classNamePrefix="student-list-filter-select"
                defaultValue={soOptions[0]}
                isSearchable={false}
                onChange={(val) => onChange("so", val.value)}
                options={soOptions}
                value={
                  soOptions.find((s) => s.value === decodeURIComponent(so)) ||
                  soOptions[0]
                }
              />
            </Grid>
          </Grid>
          {showEngagementAdvancedFilters && (
            <Grid container item md={10} xs={12}>
              <Grid item md={2} xs={12} className="student-list-filter">
                <p className="mr-8 nowrap-text">Filter by</p>
                <Select
                  className="student-list-filter-select"
                  classNamePrefix="student-list-filter-select"
                  defaultValue={jilOptions[0]}
                  isSearchable={false}
                  onChange={(val) => onChange("jil", val.value)}
                  options={jilOptions}
                  value={
                    jilOptions.find(
                      (j) => j.value === decodeURIComponent(jil),
                    ) || jilOptions[0]
                  }
                />
              </Grid>
              <Grid item md={4} xs={12} className="student-list-filter">
                <p className="mr-8 nowrap-text">Monthly engagement roundup</p>
                <Select
                  className="student-list-filter-select"
                  classNamePrefix="student-list-filter-select"
                  defaultValue={engagementRoundupOptions[0]}
                  isSearchable={false}
                  onChange={(val) => onChange("er", val.value)}
                  options={engagementRoundupOptions}
                  value={
                    engagementRoundupOptions.find(
                      (s) => s.value === decodeURIComponent(er),
                    ) || engagementRoundupOptions[0]
                  }
                />
              </Grid>
            </Grid>
          )}
          <Grid
            item
            md={1}
            xs={12}
            className="student-list-filter students-view-results"
          >
            <TableResultsView increment={15} width={"100%"} {...props} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

const CustomClearText = () => (
  <Tooltip arrow title="Delete this group">
    <Button
      className="medium-text accent-text-dark"
      style={{
        fontSize: "10px",
      }}
    >
      <i
        className={`material-icons ${"accent-text"} student-groups-heading-icons`}
        style={{ fontSize: "14px" }}
      >
        delete
      </i>{" "}
      Delete
    </Button>
  </Tooltip>
);
const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,

    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};
