import React from "react";
import { Button, Tabs, Tab, Grid, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import debounce from "lodash.debounce";
import StudentProfileModal from "./studentProfile/StudentProfileModal";
import StudentsExportButton from "./StudentsExportButton";
import StudentsImportModal from "./StudentsImportModal";
import TargetStudentModal from "./TargetStudentModal";
import StudentsList from "./studentsList/StudentsList";
import { Navigation, PageURL } from "../../lib";
import { withAppInsights } from "../../lib/AppInsights";
import Modal from "../../components/Modal";
import TextField from "../../components/form/MuiTextField";
import MaterialCheckbox from "../../components/form/MaterialCheckbox";
import ConfirmationModal from "../../components/ConfirmationModal";

class Students extends React.PureComponent {
  createGroupState = {
    createGroupInputValue: "",
    showCreateStudentGroupModal: false,
    createStudentGroupLoading: false,
    createStudentGroupError: false,
  };
  state = {
    ...this.createGroupState,
    includeArchived: false,
    showCreateStudentModal: false,
    showDeleteStudentModal: false,
    showTargetStudentModal: false,
    studentIdToDelete: null,
    studentToTarget: null,
    deleteErrorMessage: "",
    showStudentsImportModal: false,
    tabValue: "students",
    allSelected: false,
    results: this.props?.students?.data?.results || [],
    excludedStudentIds: [],
    selectedGroupName: "",
    filters: {},
  };

  componentDidMount() {
    this.toggleCreateImportModalsOnMount();
    this.props.actions.getStudentCampuses();
    this.props.actions.getAllChabadHouseTags();
    this.setState({ viewStudentsByGroup: !!this.props.viewStudentsByGroup });
  }

  toggleSelectAll = () => {
    const { results, allSelected } = this.state;
    const newResults = results?.map((s) => ({
      ...s,
      checked: !allSelected,
    }));
    this.setState({
      results: newResults,
      allSelected: !allSelected,
      excludedStudentIds: [],
    });
  };

  clearSelected = () => {
    const { results } = this.state;
    const newResults = results?.map((s) => ({
      ...s,
      checked: false,
    }));
    this.setState({
      results: newResults,
      allSelected: false,
      excludedStudentIds: [],
    });
  };

  toggleSelectStudent = (index) => {
    const results = [...(this.state.results || [])];

    if (results.length > 0) {
      results[index] = { ...results[index], checked: !results[index]?.checked };
      let excludedStudentIds;
      if (this.state.allSelected) {
        if (!results[index].checked) {
          //exclusion
          excludedStudentIds = [
            ...this.state.excludedStudentIds,
            results[index].studentID,
          ];
        } else {
          //remove exclusion
          excludedStudentIds = [...this.state.excludedStudentIds].filter(
            (s) => s !== results[index].studentID,
          );
        }
      } else {
        // not all selected
        if (results[index].checked) {
          //exclusion
          excludedStudentIds = [
            ...this.state.excludedStudentIds,
            results[index].studentID,
          ];
        } else {
          //remove exclusion
          excludedStudentIds = [...this.state.excludedStudentIds].filter(
            (s) => s !== results[index].studentID,
          );
        }
      }

      this.setState({ results, excludedStudentIds }, () => {});
    }
  };

  updateStudentGroup = async (
    isInsert,
    selectedChabadHouseTags,
    setLoading = true,
  ) => {
    let {
      chabadHouseTags: { data: allChabadHouseTags } = {},
      pageRoute: {
        query: {
          ca: campusId,
          er: engagementRoundupFilter,
          gd: gradLevel,
          ge: gender,
          gr: chabadHouseTag,
          gy: graduationYear,
          jil: jewishInterestLevel,
          p: page,
          r: resultsView,
          se: search,
          so: sortBy,
        },
      },
    } = this.props;

    const {
      allSelected: selectAll,
      results,
      excludedStudentIds,
      includeArchived,
    } = this.state;
    if (setLoading) {
      this.setState({
        results: results.map((s) => ({ ...s, loading: s.checked })),
      });
    }
    //exclude
    const studentIDsToExclude = excludedStudentIds;
    if (chabadHouseTag) {
      chabadHouseTag = parseInt(chabadHouseTag);
    }

    if (!selectedChabadHouseTags && !isInsert) {
      selectedChabadHouseTags = allChabadHouseTags?.filter(
        (t) => t.id === chabadHouseTag,
      );
    }

    await this.props.actions.updateChabadHouseGroupParticipants(
      {
        page,
        results: resultsView,
        selectAll,
        chabadHouseTags: chabadHouseTag ? [chabadHouseTag] : undefined,
        campusId: campusId ? [campusId] : undefined,
        studentIDsToExclude,
        engagementRoundupFilter,
        gender,
        gradLevel,
        graduationYear: graduationYear
          ? Array.isArray(graduationYear)
            ? graduationYear
            : [graduationYear]
          : undefined,
        jil: jewishInterestLevel,
        text: search ? decodeURIComponent(search) : search,
        includeArchived: includeArchived,
        includeDetails: true,
        includeChabadHouseTags: true,
        sortByOption: sortBy || "lastName", //default sortBy option
      },
      selectedChabadHouseTags,
      isInsert,
    );
    const hasNewTags = !!selectedChabadHouseTags.find((t) => !t.id);
    if (hasNewTags) {
      this.props.actions.getAllChabadHouseTags();
    }
    this.setState({
      allSelected: false,
      results: this.props?.students?.data?.results,
      excludedStudentIds: [],
    });
  };

  deleteTag = async () => {
    const {
      actions: { deleteChabadHouseTag },
      pageRoute: {
        query: { gr: chabadHouseTag },
      },
    } = this.props;
    if (chabadHouseTag) {
      await deleteChabadHouseTag(parseInt(chabadHouseTag));
      setTimeout(this.getStudents, 100);
    }
  };

  removeSingleParticipantFromGroup = async (studentId, tagId) => {
    this.setState({
      results: [
        ...this.state.results.map((s) => ({
          ...s,
          loading: s.studentID === studentId,
        })),
      ],
    });

    const student = await this.props.actions.removeStudentFromGroup(
      studentId,
      tagId,
    );
    if (student) {
      let st = [
        ...this.state.results.map((s) => ({
          ...s,
          allTagIds:
            s.studentID === studentId ? student.allTagIds : s.allTagIds,
          allTagNames:
            s.studentID === studentId ? student.allTagNames : s.allTagNames,
          loading: s.studentID === studentId ? false : s.loading,
        })),
      ];
      this.setState({ results: st });
    }
  };

  toggleCreateImportModalsOnMount = () => {
    const {
      pageRoute: { query, page, params },
    } = this.props;
    const { showCreate, showImport, gr } = query;

    if (showCreate || showImport || gr) {
      this.setState({
        showCreateStudentModal: !!showCreate,
        showStudentsImportModal: !!showImport,
        tabValue: !!gr ? "groups" : "students",
      });

      //clear showCreate/Import query params
      Navigation.redirect(
        PageURL.to(page, params, {
          ...query,
          showCreate: undefined,
          showImport: undefined,
        }),
      );
    }
  };
  updateArchiveStatus = async (studentId, shouldArchive) => {
    await this.props.actions.updateArchiveStatus(studentId, shouldArchive);
    this.setState({ results: this.props?.students?.data?.results });
  };
  deleteStudent = async (studentId) => {
    this.setState({
      showDeleteStudentModal: true,
      studentIdToDelete: studentId,
      deleteErrorMessage: "",
    });
  };
  onDeleteStudent = async () => {
    const deleteState = await this.props.actions.deleteStudent(
      this.state.studentIdToDelete,
    );
    const { errorMessage, success } = deleteState;
    if (success) {
      this.getStudents();
      this.setState({
        showDeleteStudentModal: false,
        studentIdToDelete: null,
      });
    } else {
      this.setState({
        deleteErrorMessage:
          errorMessage ||
          "Something went wrong and student could not be deleted.",
      });
    }
  };

  getStudents = async () => {
    const {
      pageRoute: {
        query: propQuery = {},
        query: {
          ca: campusId,
          er: engagementRoundupFilter,
          gd: gradLevel,
          ge: gender,
          gr: chabadHouseTagId,
          gy: graduationYear,
          jil: jewishInterestLevel,
          p: page,
          r: resultsView,
          se: search,
          so: sortBy,
        },
      },
    } = this.props;
    const { filters, includeArchived } = this.state;

    await this.props.actions.getStudents(page, resultsView, {
      chabadHouseTags: chabadHouseTagId,
      campusId,
      engagementRoundupFilter,
      gender,
      graduationYear,
      gradLevel,
      jewishInterestLevel,
      includeArchived,
      includeChabadHouseTags: true,
      search: search ? decodeURIComponent(search) : search,
      sortBy: sortBy || "lastName", //default sortBy option
    });

    // now we deal with if we should be clearing the checkboxs / selections
    //we clear for any filter change besides for sort, page and number of rows per page
    let allSelected = false;

    const { p, r, so, ...query } = propQuery;
    // if one of the filters are not here there was a change in filters
    let clear = Object.keys(filters).length !== Object.keys(query).length;

    if (!clear) {
      for (const [key, value] of Object.entries(filters)) {
        if (query[key] !== value) {
          clear = true;
        }
      }
    }

    if (!clear && this.state.allSelected === true) {
      allSelected = true;
    }
    const results = this.props?.students?.data?.results?.map((s) => {
      const isExcluded = this.state.excludedStudentIds?.some(
        (ex) => ex === s.studentID,
      );
      const checked =
        !clear &&
        ((allSelected && !isExcluded) || (!allSelected && isExcluded));
      return {
        ...s,
        checked,
      };
    });

    this.setState({
      allSelected: allSelected,
      filters: query,
      excludedStudentIds: clear ? [] : this.state.excludedStudentIds,
      results,
      selectedGroupName: this.props.chabadHouseTags?.data?.find(
        (t) => t.id === parseInt(chabadHouseTagId),
      )?.tagName,
    });
  };

  targetStudent = async (student) => {
    this.setState({
      showTargetStudentModal: true,
      studentToTarget: student,
    });
  };

  onChange = (name, val, debounce) => {
    const {
      pageRoute: { query, page, params },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      [name]: val ? encodeURIComponent(val) : undefined,
      p: undefined, //reset page to 1
    });
    Navigation.redirect(url);

    setTimeout(debounce ? this.onFilterDebounce : this.onFilter, 0);
  };

  onFilter = () => {
    this.getStudents();
  };

  onFilterDebounce = debounce(this.onFilter, 500);

  submitNewGroup = async () => {
    const { createGroupInputValue } = this.state;
    if (!createGroupInputValue) {
      this.setState({ createStudentGroupError: true });
      return;
    }

    await this.props.actions.addChabadHouseTags([createGroupInputValue]);
    await this.props.actions.getAllChabadHouseTags();
    this.closeCreateGroupModal();
  };

  closeCreateGroupModal = () => {
    this.setState({
      showCreateStudentGroupModal: false,
      createStudentGroupError: false,
      createStudentGroupLoading: false,
      createGroupInputValue: "",
    });
  };

  render() {
    const {
      actions: { submitStudentsImport },
      pageRoute,
      studentCampuses,
      studentsImport,
      students: { data: { results: studentsResults, numberOfRows } = {} },
    } = this.props;

    const { se = "" } = pageRoute.query;

    const {
      allSelected,
      createStudentGroupLoading,
      excludedStudentIds,
      includeArchived,
      showCreateStudentModal,
      showDeleteStudentModal,
      showTargetStudentModal,
      deleteErrorMessage,
      showCreateStudentGroupModal,
      showStudentsImportModal,
      studentToTarget,
      tabValue,
      createStudentGroupError,
      selectedGroupName,
      createGroupInputValue,
    } = this.state;
    const numberOfChecked = allSelected
      ? numberOfRows - excludedStudentIds?.length
      : excludedStudentIds?.length;

    return (
      <div className="students-page">
        <>
          <Modal show={showCreateStudentGroupModal}>
            <div className="student-details-modal-container modal-container full-page-mobile-modal-container">
              <div className="sms-compose-mes-modal">
                <div className="large-text">Create student Group</div>
                <div className="mt-20">
                  <div>
                    <TextField
                      error={createStudentGroupError}
                      id="outlined-basic"
                      label="New group name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      className="mui-text-field"
                      value={createGroupInputValue}
                      onChange={(e) => {
                        this.setState({
                          createGroupInputValue: e.target.value,
                          createStudentGroupError: false,
                        });
                      }}
                      helperText={`${
                        createStudentGroupError ? "Group name required" : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="buttons-section">
                  <button
                    className="btn btn-light mr-16"
                    onClick={this.closeCreateGroupModal}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={async () => {
                      this.submitNewGroup();
                    }}
                    className="btn btn-accent"
                  >
                    {createStudentGroupLoading ? "Adding..." : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <div className="card">
            <Typography variant="h4" className="desktop-hidden">
              My Students
            </Typography>

            <Tabs
              className="mb-16"
              indicatorColor="primary"
              textColor="primary"
              value={tabValue}
              onChange={(_, value) => {
                if (value === "students") {
                  const {
                    pageRoute: { query, page, params },
                  } = this.props;

                  const url = PageURL.to(page, params, {
                    ...query,
                    gr: undefined,
                    p: undefined, //reset page to 1
                  });
                  Navigation.redirect(url);
                  setTimeout(this.getStudents, 100);
                }
                this.setState({
                  tabValue: value,
                });
                this.clearSelected();
              }}
            >
              <Tab label="Students" value="students" />
              <Tab label="Student Groups" value="groups" />
            </Tabs>

            <Grid
              container
              justifyContent="space-between"
              direction="row"
              className="mb-8"
            >
              <Grid item xs={12} md={4} className="search-input">
                <input
                  name="se"
                  onChange={(event) =>
                    this.onChange(event.target.name, event.target.value, true)
                  }
                  placeholder="Search Student Name"
                  type="text"
                  value={decodeURIComponent(se)}
                />
                <i className="material-icons accent-text-secondary pointer">
                  search
                </i>
              </Grid>

              <div className="students-buttons-list">
                <div className="text-right">
                  <MaterialCheckbox
                    color="primary"
                    label={"Show archived"}
                    checked={includeArchived}
                    onChange={() =>
                      this.setState(
                        { includeArchived: !includeArchived },
                        this.getStudents,
                      )
                    }
                  />
                </div>
                <div className="students-buttons-container">
                  {tabValue === "groups" ? (
                    <div style={{ display: "contents" }}>
                      <Button
                        className="mr-16"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          this.setState({
                            showCreateStudentGroupModal: true,
                          })
                        }
                      >
                        <Add className="mr-8" /> New Group
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="mr-16"
                        onClick={() =>
                          this.setState({ showCreateStudentModal: true })
                        }
                      >
                        <Add className="mr-8" /> New Student
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="mobile-hidden mr-16"
                        onClick={() =>
                          this.setState({ showStudentsImportModal: true })
                        }
                      >
                        Import
                      </Button>
                    </>
                  )}
                  <StudentsExportButton
                    disabled={!studentsResults || !studentsResults.length}
                    includeArchived={includeArchived}
                    pageRoute={pageRoute}
                  />
                </div>
              </div>
            </Grid>
            <StudentsList
              deleteTag={this.deleteTag}
              getStudents={this.getStudents}
              {...this.props}
              showSearch={false}
              viewStudentsByGroup={tabValue === "groups"}
              allSelected={this.state.allSelected}
              numberSelected={numberOfChecked}
              excludedStudentIds={excludedStudentIds}
              results={this.state.results}
              toggleSelectAll={this.toggleSelectAll}
              toggleSelectStudent={this.toggleSelectStudent}
              updateStudentGroup={this.updateStudentGroup}
              updateArchiveStatus={this.updateArchiveStatus}
              deleteStudent={this.deleteStudent}
              targetStudent={this.targetStudent}
              removeSingleParticipantFromGroup={
                this.removeSingleParticipantFromGroup
              }
              selectedGroupName={selectedGroupName}
            />
          </div>

          {/* TODO: CSS - can't have 2 modals rendering on the same page bec the second reverses body overflow setting of the first! */}
          {showCreateStudentModal && (
            <StudentProfileModal
              close={() => this.setState({ showCreateStudentModal: false })}
              onSubmit={this.getStudents}
              show={showCreateStudentModal}
            />
          )}

          {showDeleteStudentModal && (
            <ConfirmationModal
              cancel={() =>
                this.setState({
                  showDeleteStudentModal: false,
                  studentIdToDelete: null,
                  deleteErrorMessage: "",
                })
              }
              confirm={this.onDeleteStudent}
              message="Are you sure you want to delete this student? This cannot be undone."
              show={showDeleteStudentModal}
              title="Delete"
              confirmText="Delete"
              confirmColor="#e94746"
              errorMessage={deleteErrorMessage}
            />
          )}
          {showTargetStudentModal && (
            <TargetStudentModal
              show={showTargetStudentModal}
              close={() =>
                this.setState({
                  showTargetStudentModal: false,
                  studentToTarget: null,
                })
              }
              onSubmit={this.getStudents}
              student={studentToTarget}
              interactionTypes={this.props.sys?.interactionTypes}
            />
          )}
          {showStudentsImportModal && (
            <StudentsImportModal
              close={() => this.setState({ showStudentsImportModal: false })}
              importStudents={submitStudentsImport}
              reloadStudents={this.getStudents}
              show={showStudentsImportModal}
              studentCampuses={studentCampuses}
              studentsImport={studentsImport}
            />
          )}
        </>
      </div>
    );
  }
}

export default withAppInsights(Students);
