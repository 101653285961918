import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tabs, Tab, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import ActivityList from "./list/ActivityList";
import StudentInteractionsSummary, {
  StudentInteractionsSummaryExportButton,
} from "./summary/StudentInteractionsSummary";
import StudentsActivityExportButton from "./StudentsActivityExportButton";
import StudentInteractionModal from "./StudentInteractionModal.vm";
import { PageURL, Navigation, IsApiErrorStatus } from "../../../lib";
import {
  AuthSelectors,
  EngagementActions,
  StudentsActions,
  StudentsSelectors,
} from "../../../state";
import { getStudentInteractionsSummaryAsync } from "../../../state/engagement/EngagementApi";

export default function StudentsActivity(props) {
  const {
    isLamplightersActivity,
    pageRoute,
    pageRoute: {
      page,
      query: {
        interactionId,
        tab: currentTab = "activity",
        p: resultsPage = 1,
        r: resultsView = 15,
        rt: rangeType,
        it: interactionType,
        gy: gradYear,
        se: search,
        so: sortBy = "dateDescending",
      },
    },
    // only for isLamplightersActivity
    schedule: { data: { id: grantScheduleId } = {} } = {},
  } = props;

  const dispatch = useDispatch();

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);

  const [isCloningInteraction, setIsCloningInteraction] = useState(false);
  const [selectedStudentInteractionId, setSelectedStudentInteractionId] =
    useState(null);
  const [showStudentInteractionModal, setShowStudentInteractionModal] =
    useState(false);
  const [isEnrolledInEngagement, setIsEnrolledInEngagement] = useState(false);
  const [defaultInteractionTypes, setDefaultInteractionTypes] = useState();

  useEffect(
    function checkEngagementAccess() {
      async function runCheck() {
        // dummy call to check whether user is enrolled in engagement portal
        const result = await getStudentInteractionsSummaryAsync(1, 0, {});
        return !IsApiErrorStatus(result.error, 403);
      }
      isShliach
        ? runCheck().then((isEnrolled) => setIsEnrolledInEngagement(isEnrolled))
        : setIsEnrolledInEngagement(false);
    },
    [isShliach],
  );

  // isEnrolledInEngagement is set by a promise, which is a truthy value even when unresolved
  const showSummary =
    isEnrolledInEngagement === true && !isLamplightersActivity;

  const tabs = ["activity", "summary"];

  const getSummaryTabs = () => (
    <Tabs
      style={{ marginBottom: 16 }}
      indicatorColor="primary"
      textColor="primary"
      value={currentTab}
      onChange={(e, value) => onChangeTab(value)}
    >
      {tabs.map((t) => (
        <Tab label={t} value={t} key={t} />
      ))}
    </Tabs>
  );

  const onChangeTab = (newTab) => {
    const url = PageURL.to(page, null, { tab: newTab });
    Navigation.redirect(url);
  };

  const isCurrentTabActivity = useCallback(
    () => currentTab === "activity",
    [currentTab],
  );

  const { data: { results: activity } = {} } = useSelector(
    StudentsSelectors.activity,
  );

  useEffect(
    function openSpecifiedInteraction() {
      if (interactionId) {
        setSelectedStudentInteractionId(interactionId);
        setShowStudentInteractionModal(true);
      }
    },
    [interactionId],
  );

  const getActivityParams = useCallback(() => {
    const filters = {
      rangeType,
      search: search ? decodeURIComponent(search) : search,
      sortBy,
    };

    if (isLamplightersActivity) {
      filters.grantScheduleId = grantScheduleId;
    }

    return { resultsPage, resultsView, filters };
  }, [
    isLamplightersActivity,
    rangeType,
    resultsPage,
    resultsView,
    search,
    sortBy,
    grantScheduleId,
  ]);

  const getActivity = useCallback(() => {
    const { resultsPage, resultsView, filters } = getActivityParams();
    dispatch(
      StudentsActions.getStudentsActivity(resultsPage, resultsView, filters),
    );
  }, [dispatch, getActivityParams]);

  useEffect(
    function reloadActivityData() {
      if (isCurrentTabActivity()) {
        getActivity();
        window.scrollTo({ left: 0, top: 0 });
      }
    },
    [isCurrentTabActivity, getActivity],
  );

  const onChooseInteraction = (interaction, isCloningInteraction = false) => {
    setIsCloningInteraction(isCloningInteraction);
    setSelectedStudentInteractionId(interaction?.id);
    setShowStudentInteractionModal(true);
  };

  const getExportButton = () =>
    isCurrentTabActivity() ? (
      <StudentsActivityExportButton
        disabled={!activity || !activity.length}
        getActivityParams={getActivityParams}
      />
    ) : (
      <StudentInteractionsSummaryExportButton
        pageRoute={pageRoute}
        defaultInteractionTypes={defaultInteractionTypes}
      />
    );

  return (
    <div>
      <div className="activity-page-title-container">
        <Typography variant="h4">
          {isLamplightersActivity ? "Lamplighters " : ""}Activity
        </Typography>
        <div className="flex flex-align-center">
          <Button
            variant="contained"
            className="mr-16 "
            size="large"
            color="secondary"
            onClick={() => setShowStudentInteractionModal(true)}
          >
            <Add /> Interaction
          </Button>
          {getExportButton()}
        </div>
      </div>

      {showSummary && getSummaryTabs()}

      {isCurrentTabActivity() ? (
        <ActivityList
          getActivity={getActivity}
          onEditInteraction={onChooseInteraction}
          onCloneInteraction={(interaction) => {
            onChooseInteraction(interaction, true);
          }}
          {...props}
        />
      ) : (
        <StudentInteractionsSummary
          pageRoute={pageRoute}
          defaultInteractionTypes={defaultInteractionTypes}
          setDefaultInteractionTypes={setDefaultInteractionTypes}
        />
      )}

      {showStudentInteractionModal && (
        <StudentInteractionModal
          close={() => {
            setIsCloningInteraction(false);
            setSelectedStudentInteractionId(null);
            setShowStudentInteractionModal(false);
          }}
          interactionId={selectedStudentInteractionId}
          isCloningInteraction={isCloningInteraction}
          onSubmitInteraction={(interaction) => {
            if (isCloningInteraction) {
              setIsCloningInteraction(false);
              setSelectedStudentInteractionId(null);
            }
            if (isCurrentTabActivity()) {
              getActivity(interaction);
            } else {
              dispatch(
                EngagementActions.getStudentInteractionsSummary(
                  resultsPage,
                  resultsView,
                  { search, rangeType, interactionType, gradYear },
                ),
              );
            }
          }}
          show={showStudentInteractionModal}
        />
      )}
    </div>
  );
}
